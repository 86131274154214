<template>
  <div>
    <v-layout class="p-4 border-bottom-light-grey min-height-57px">
      <v-flex class="font-level-3-bold my-auto">
        <span class="detail-svg-icon mr-2">
          <!--begin::Svg Icon-->
          <!--   <v-icon color="blue darken-4">mdi-account-group</v-icon> -->
          <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
            <inline-svg :src="$assetURL('media/custom-svg/supplier.svg')" />
            <!--end::Svg Icon-->
          </span>

          <!--end::Svg Icon-->
        </span>
        Suppliers
      </v-flex>
    </v-layout>
    <div
      class="overflow-y"
      style="max-height: calc(100vh - 306px)"
      v-if="!pageLoading"
    >
      <template v-if="supplers.length">
        <table
          width="100%"
          class="detail-table table-head-sticky"
          style="table-layout:'fixed"
        >
          <thead>
            <tr>
              <th width="25%" class="p-2 blue lighten-4 text-left">
                Supplier #
              </th>
              <th width="25%" class="p-2 blue lighten-4">Name</th>
              <th width="25%" class="p-2 blue lighten-4">Supplier Code</th>
              <th width="25%" class="p-2 blue lighten-4 text-center">Price</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in supplers" :key="index">
              <td class="p-2 border-top-light-grey text-left">
                <div v-if="row && row.suppliers_barcode" class="m-2">
                  <span
                    class="fw-500"
                    v-on:click="RequestPoDetail(row.supplier)"
                  >
                    <Barcode :barcode="row.suppliers_barcode"></Barcode>
                  </span>
                </div>
              </td>
              <td class="p-2 border-top-light-grey">
                {{ row.display_name }}
                <!-- <ShowValue :object="row" object-key="display_name" label="display name"></ShowValue> -->
              </td>
              <td class="p-2 border-top-light-grey">
                <template v-if="row.part_number">
                  {{ row.part_number }}
                </template>
                <template v-else>
                  <em class="text-muted">no supplier code</em>
                </template>
                <!-- {{ row.part_number }} -->
                <!-- 								<ShowValue :object="row" object-key="part_number" label="supplier code"></ShowValue> -->
              </td>
              <td class="p-2 border-top-light-grey text-center">
                <v-chip label>
                  {{ formatMoney(row.price) }}
                </v-chip>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <p v-else class="m-0 row-not-found text-center">
        <img
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image"
        />
        Uhh... There are no supplier at the moment.
      </p>
    </div>
    <div v-else class="mx-20 my-40 text-center">
      <v-progress-linear
        color="orange darken-2"
        indeterminate
        height="6"
      ></v-progress-linear>
    </div>
  </div>
</template>
<script>
//import ApiService from "@/core/services/api.service";
//import ShowValue from "@/view/components/ShowValue";
// import ImageTemplate from "@/view/components/Image";
import { GET } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
export default {
  mixins: [CommonMixin],
  name: "SupplierListing",
  props: {
    typeText: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    typeUuid: {
      type: String,
      default: null,
    },
    product: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      supplers: [],
      pageLoading: true,
    };
  },
  methods: {
    RequestPoDetail(uuid) {
      //console.log(uuid);
      this.$router.replace(
        this.getDefaultRoute("supplier.detail", {
          params: { id: uuid },
        })
      );
    },

    // getSupplier() {
    // 	ApiService.get(`${this.type}/${this.typeUuid}/suppliers`)
    // 		.then(({ data }) => {
    // 			this.supplers = data.data;
    // 		})
    // 		.catch((error) => {
    // 			this.$emit("error", error);
    // 		})
    // 		.finally(() => {
    // 			this.pageLoading = false;
    // 		});
    // },
    getSupplier() {
      this.$store
        .dispatch(GET, {
          url: "product/" + this.product + "/suppliers",
        })
        .then(({ data }) => {
          this.supplers = data.data;
        })
        .catch((error) => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
  components: {
    //ShowValue,
    Barcode,
  },
  mounted() {
    this.getSupplier();
  },
};
</script>
